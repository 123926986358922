import "./App.css";
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Navbar } from "./components/navbar/navbar";
import { Contact } from "./pages/contact/contact";
import { Footer } from "./components/footer/footer";
import style from "./app.module.scss";
import { AboutPage } from "./pages/about/about";

class App extends React.Component {
    render() {
        return (
            <div className={style.pageContainer}>
                <div className={style.backgroundImage}>
                    <div className={style.overlay}></div>
                    <img src="/images/background.jpeg" alt="" />
                </div>
                <Router>
                    <Navbar />
                    <Switch>
                        <Route path="/" exact>
                            <AboutPage />
                        </Route>
                        <Route path="/contact" exact>
                            <Contact />
                        </Route>
                        <Redirect to='/'/>
                    </Switch>
                </Router>
                <Footer className={style.footer} />
            </div>
        );
    }
}

export default App;
