import React, { useEffect, useState } from "react";
import { LifeBuoy, X } from "react-feather";
import style from "./about.module.scss";
import ClickOutside from "@tntd/react-click-outside";
import { SinglePost } from "../../components/single-post/single-post";

const jynPhotos = [
    "/images/jyn-1.jpeg",
    "/images/jyn-2.jpeg",
    "/images/jyn-3.jpeg",
];
const lilaPhotos = [
    "/images/lila-1.jpg",
    "/images/lila-2.jpg",
    "/images/lila-3.jpg",
    "/images/lila-4.jpg",
];

const Dog = ({ image, name, breed, description }) => {
    const [isDogModalOpened, setIsDogModalOpened] = useState(false);
    return (
        <>
            {isDogModalOpened ? (
                <div className={style.modal}>
                    <div className={style.overlay}></div>
                    <ClickOutside
                        className={style.dogModal}
                        onClickOutside={() => {
                            document.body.style.overflowY = "auto";
                            setIsDogModalOpened(false);
                        }}
                    >
                        <div
                            className={style.close}
                            onClick={() => {
                                document.body.style.overflowY = "auto";
                                setIsDogModalOpened(false);
                            }}
                        >
                            <X />
                        </div>
                        <SinglePost
                            postData={{
                                zdjecia:
                                    name === "Jyn" ? jynPhotos : lilaPhotos,
                                tytul: `${name}, ${breed}`,
                                tresc: description,
                            }}
                            dogModal={true}
                        />
                        <div></div>
                    </ClickOutside>
                </div>
            ) : null}
            <div
                className={style.dogCard}
                onClick={() => {
                    document.body.style.overflowY = "hidden";
                    setIsDogModalOpened(true);
                }}
            >
                <div className={style.imgContainer}>
                    <img src={image} alt="" />
                </div>
                <div className={style.description}>
                    <span className={style.dogName}>{name}</span>,
                    <span className={style.dogBreed}> {breed}</span>
                </div>
            </div>
        </>
    );
};

export const AboutPage = () => {
    useEffect(()=>{
        window.scroll(0,0)
    },[])
    return (
        <div className={style.aboutPageContainer}>
            <div className={style.upperCover}></div>
            <div className={style.content}>
                <h1>Witamy Cię z Psiego Chaosu</h1>
                <h4 style={{ fontWeight: 400 }}>
                    Hodujemy świetne Welsh Terriery i West Highland White
                    Terriery od ponad 20 lat!
                </h4>
                <div className={style.sectionContainer}>
                    <h4>Nasze suki hodowlane</h4>
                    <div className={style.ourDogs}>
                        <Dog
                            image="/images/lila-1.jpg"
                            name="Lila"
                            breed="Welsh Terrier"
                            description="Terier Walijski w porównaniu do lakeland teriera bardziej towarzyski i mniej zaczepny, łatwiejszy do prowadzenia, spokojniejszy. Skore są do zabawy i bardzo wesołe, rzadko bojaźliwe, z reguły pewne siebie. Ze względu na wrodzony upór wobec innych członków rodziny, należy wychowywać go konsekwentnie i odpowiedzialnie. Dobrze znoszą podróże i nie obawiają się nowych miejsc. Silnie związane ze swoim terytorium, którego konsekwentnie bronią. Jest skłonny do dominacji wobec innych zwierząt."
                        />
                        <Dog
                            image="/images/jyn-1.jpeg"
                            name="Jyn"
                            breed="West Highland White Terrier"
                            description="West highland white terrier to pies inteligentny, przywiązujący się najczęściej do całej rodziny. Jest żwawy, zawsze chętny do zabawy. Cechuje go odwaga oraz pewność siebie. Lubi biegać oraz uprawiać różne sporty (może być wspaniałym towarzyszem dla osób szczególnie wysportowanych). Jest to rasa spotykana w takich konkurencjach jak agility, obedience i dogdancing."
                        />
                    </div>
                </div>
                <div className={style.sectionContainer}>
                    <h4>Hodowla</h4>
                    <div className={style.subheading}>
                        To nie firma tylko ludzie, wkładający mnóstwo serca w
                        swoją pracę, dlatego chcemy, żebyś poznał nas bardziej.
                    </div>
                    <h4>Kim jesteśmy?</h4>
                    <div className={style.subheading}>
                        Jako rodzina, to troje ludzi i zawsze kilka psów.
                        Zaczęło się niewinnie, od jednej malutkiej suki, aby
                        przerodzić się w coś, co jest sensem życia. Nie
                        wyobrażamy sobie, żeby kiedykolwiek zabrakło jakiś
                        czterech łap w naszym życiu. Bo kto będzie ładował się
                        na kolana? Kto będzie grzał bok w łóżku? Kto zostawi
                        błotne ślady na kafelkach po powrocie z deszczowego
                        podwórka? MY to ludzie i psy, nie ma innej możliwości.
                    </div>
                    <div className={style.aboutUsContainer}>
                        <div className={style.person}>
                            <div className={style.name}>Basia</div>
                            <div className={style.subheading}>
                                Zapoczątkowała Wszystko
                            </div>
                            <div className={style.personDescription}>
                                <p>
                                    Jej pierwszy pies był pudlem średnim,
                                    przyleciał samolotem jako prezent od
                                    dziadka. Dla nastoletniej Basi to
                                    doświadczenie było pierwszym psim krokiem w
                                    życiu. Jednak dalsze psie miłości musiały
                                    poczekać, ale kiedy się już doczekała… To
                                    pomysł na hodowlę przyszedł naprawdę zaraz
                                    po tym.
                                </p>
                                <p>
                                    W 1998 pojawiła się pierwsza terierka
                                    walijska, to ona dała początek hodowli. To
                                    jej pierwszy miot szczeniaków w 2000 roku
                                    jest jednocześnie rokiem, w którym zaczęła
                                    się Życiowa Przygoda, jaką jest hodowla “z
                                    Psiego Chaosu”. To również dzięki tej
                                    pierwszej suce walijce Basia skontaktowała
                                    się z chorzowskim Oddziałem Związku
                                    Kynologicznego, trafiając na jednego z
                                    najlepszych terierowców w Polsce - Andrzeja
                                    Jaworskiego, który stał się jej mentorem i
                                    to Jego rękami i oczami znawcy wybierane
                                    były pierwsze teriery do hodowli. Zaś w
                                    oddziale po dziś dzień się udziela (już jako
                                    członek uhonorowany złotą odznaką za swoją
                                    działalność).
                                </p>
                                <p>
                                    Kolejnym krokiem było kupno, z miłości do
                                    rasy, suki labradorki Ery, a w zasadzie już
                                    rok później pojawiła się Parsonka Leica
                                    (która pochodzi z hodowli “Półdiable” - tej
                                    pierwszej, która może kojarzyć się z tą rasą
                                    w Polsce!). Kiedy już wszyscy ludzcy
                                    członkowie rodziny mieli swoje psie
                                    odpowiedniki, nazywane humorystycznie “czi
                                    psami” magiczna liczba trzy została złamana
                                    i tak pojawiali się kolejni psi członkowie
                                    naszej rodziny. Westiczka Cora (imię na
                                    cześć pierwszej pudlicy z młodzieńczych
                                    lat), czy już wychowane dumnie pod własnym
                                    przydomkiem przedstawicielki hodowanych ras.
                                </p>
                            </div>
                        </div>
                        <div className={style.divider}></div>
                        <div className={style.person}>
                            <div className={style.name}>Marta</div>
                            <div className={style.subheading}>
                                Uczestniczyła od dzieciństwa
                            </div>
                            <div className={style.personDescription}>
                                <p>
                                    Miała jedenaście lat, kiedy w domu pojawiła
                                    się terierka walijska Arnica. To właśnie ta
                                    suka obudziła totalną miłość do psów i to
                                    dzięki Arnice przyswajanie kynologicznej
                                    wiedzy i zatapianie się w książkach dla psów
                                    i o psach przyszło, jako coś zupełnie
                                    naturalnego i prostego.
                                </p>
                                <p>
                                    Dwa lata później to Marta wymyśliła
                                    przydomek dla hodowli. Zakochana w mitologii
                                    zrozumiała, że skoro bogowie wyłonili się z
                                    chaosu… to nie może być nic innego niż “z
                                    Psiego Chaosu”. W końcu psy to było jej
                                    centrum wszechświata.
                                </p>
                                <p>
                                    Jeszcze jako nastolatka zaczęła udzielać się
                                    wraz z Mamą w oddziale Związku
                                    Kynologicznego (obecnie uhonorowana srebrną gwiazdą za działalność), pracując przy wielu
                                    wystawach macierzystego oddziału, jak i
                                    oddziałów okolicznych. W 2014 roku
                                    oficjalnie została współwłaścicielką
                                    hodowli, którą współtworzyła wraz ze swoją
                                    Mamą. Obecnie, mimo nieco innego miejsca
                                    zamieszkania, nie przestaje być wsparciem i
                                    współautorem Hodowli wraz ze wszystkimi jej
                                    porażkami i sukcesami.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.sectionContainer}>
                    <h4>Kiedy pojawi się nowy domownik...</h4>
                    <div className={style.subheading}>
                        Zdajemy sobie sprawę, że zwierzak w domu potrafi być
                        nielada wyzwaniem, dlatego zawsze chętnie służymy
                        pomocą.
                    </div>
                    <div className={style.extraFeaturesContainer}>
                        <div className={style.extraFeaturesRow}>
                            <div className={style.extraFeature}>
                                <LifeBuoy />
                                <div className={style.heading}>
                                    PEŁNE WSPARCIE
                                </div>
                                <div className={style.featureDescription}>
                                    Na ma znaczenia jak długo masz psa. Z Psiego
                                    Chaosu zawsze otrzymasz wsparcie, dlatego
                                    ludzie nam ufają!
                                </div>
                            </div>
                            <div className={style.extraFeature}>
                                <LifeBuoy />
                                <div className={style.heading}>
                                    ZAPASY JEDZENIA
                                </div>
                                <div className={style.featureDescription}>
                                    Jeśli chcesz zapewnić najwyższej jakości
                                    jedzenie dla swojego psiaka nie szukaj
                                    dalej, bo my możemy Ci je zapewnić.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.bottomCover}></div>
        </div>
    );
};
