import React, { useState, useEffect } from "react";
import { Menu } from "react-feather";
import ClickOutside from "@tntd/react-click-outside";
import { Link } from "react-router-dom";
import style from "./navbar.module.scss";

const MenuOptions = () => {
    return (
        <>
            <Link to="/">O nas</Link>
            <a href="https://www.facebook.com/zpsiegochaosu" target="_blank" rel="noreferrer">Aktualności</a>
            <Link to="/contact">Kontakt</Link>
        </>
    );
};

export const Navbar = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isBurgerMenuOpened, setBurgerMenuOpened] = useState(false);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    return (
        <div className={style.navbarContainer}>
            <div
                className={style.navbarBackground}
                style={{ opacity: scrollPosition / 100 }}
            ></div>
            <div className={style.leftSide}>
                <Link to="/" className={style.logo}>
                    z Psiego Chaosu FCI
                </Link>
            </div>
            <div className={style.rightSide}>
                <ClickOutside onClickOutside={() => setBurgerMenuOpened(false)}>
                    <div
                        className={style.burgerMenu}
                        onClick={() => {
                            setBurgerMenuOpened(!isBurgerMenuOpened);
                        }}
                    >
                        <Menu color="white" />
                        {isBurgerMenuOpened ? (
                            <div className={style.opened}>
                                <MenuOptions />
                            </div>
                        ) : null}
                    </div>
                </ClickOutside>
                <div className={style.full}>
                    <MenuOptions />
                </div>
            </div>
        </div>
    );
};
