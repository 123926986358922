import React from "react";
import { Facebook, Mail, Phone, Send } from "react-feather";
import ReCAPTCHA from "react-google-recaptcha";
import style from "./contact.module.scss";
import axios from 'axios'

export class Contact extends React.Component {
    state = {
        contactName: "",
        contactEmail: "",
        contactMessage: "",
        emailValid: false,
        submitted: false,
        capcha: null,
        sendStatus: 'not-send',
    };

    componentDidMount() {
        window.scroll(0, 0);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ submitted: true });
        if (
            this.state.contactMessage.length &&
            this.state.contactName.length &&
            this.state.emailValid &&
            this.state.capcha
        ) {
            axios.post('/sendMail', {
                name: this.state.contactName,
                email: this.state.contactEmail,
                message: this.state.contactMessage
            }).then((res)=>{
                console.log(res)
                this.setState({
                    sendStatus: 'sent'
                })
            }).catch(e=>{
                this.setState({
                    sendStatus: 'error'
                })
            })
        }
    };

    validateEmail = (e) => {
        const isCorrectEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
            e.target.value
        );

        this.setState({
            contactEmail: e.target.value.trim(),
            emailValid: isCorrectEmail,
            submitted: false,
        });
    };

    render() {
        return (
            <div className={style.contactContainer}>
                <h1>Zawsze chętni do pomocy</h1>

                <div className={style.contactTypesContainer}>
                    <div className={style.formContainer}>
                        <h4>Zapytaj o najbliższy miot</h4>
                        <form onSubmit={this.handleSubmit}>
                            {this.state.submitted ? (
                                !this.state.contactMessage.length ||
                                !this.state.contactName.length ||
                                !this.state.emailValid ||
                                !this.state.capcha ? (
                                    <span
                                        style={{
                                            color: "red",
                                            alignSelf: "center",
                                            marginTop: "1rem",
                                            textAlign: "center",
                                        }}
                                    >
                                        Nie wszystkie pola zostały wypełnione
                                    </span>
                                ) : this.state.sendStatus === 'sent' ? (
                                    <span
                                        style={{
                                            color: "green",
                                            alignSelf: "center",
                                            marginTop: "1rem",
                                            textAlign: "center",
                                        }}
                                    >
                                        Twoja wiadomość została wysłana.
                                        Odpowiemy jak najszybiej to możliwe.
                                    </span>
                                ) : (<span
                                    style={{
                                        color: this.state.sendStatus === 'error'? "red": '#333',
                                        alignSelf: "center",
                                        marginTop: "1rem",
                                        textAlign: "center",
                                    }}
                                >
                                   {this.state.sendStatus === 'error' ? 'Wystąpił błąd w trakcie wysyłania wiadomości.' : 'Wysyłam...'}
                                </span>)
                            ) : null}
                            <div className={style.formElement}>
                                <input
                                    id="name"
                                    type="text"
                                    required
                                    value={this.state.contactName}
                                    onChange={(e) => {
                                        this.setState({
                                            contactName: e.target.value,
                                            submitted: false,
                                        });
                                    }}
                                />
                                <label htmlFor="name">Imię</label>
                            </div>
                            <div className={style.formElement}>
                                <input
                                    id="email"
                                    type="email"
                                    required
                                    value={this.state.contactEmail.trim()}
                                    onChange={(e) => {
                                        this.validateEmail(e);
                                    }}
                                />
                                <label htmlFor="email">
                                    {!this.state.contactEmail.length ? (
                                        "Email"
                                    ) : !this.state.emailValid ? (
                                        <span
                                            style={{
                                                fontSize: "0.7rem",
                                                color: "red",
                                            }}
                                        >
                                            Proszę podać poprawny email
                                        </span>
                                    ) : (
                                        <span
                                            style={{
                                                fontSize: "0.7rem",
                                                color: "green",
                                            }}
                                        >
                                            Dziękujemy za podanie emaila
                                        </span>
                                    )}
                                </label>
                            </div>
                            <div className={style.formElement}>
                                <textarea
                                    id="message"
                                    required
                                    rows="5"
                                    value={this.state.contactMessage}
                                    onChange={(e) => {
                                        this.setState({
                                            contactMessage: e.target.value,
                                            submitted: false,
                                        });
                                    }}
                                />
                                <label htmlFor="message">Wiadomość</label>
                            </div>
                            <ReCAPTCHA
                                sitekey="6Le9cv0ZAAAAACd0crBGUN_-54jWlsJpQSTAg2YY"
                                onChange={(v) => {
                                    this.setState({ submitted: false, capcha: v });
                                }}
                            />
                            <div className={style.formElement}>
                                <button type="submit">
                                    <Send size={16} />
                                    Wyślij
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className={style.formContainer}>
                        <h4>Skontaktuj się bezpośrednio</h4>
                        <div className={style.otherContactTypes}>
                        <div className={style.contactType}>
                            <a href="tel:+48506954169">
                                <Phone size={20} /> +48 506 954 169
                            </a>
                        </div>
                        <div className={style.contactType}>
                            <a
                                href="https://www.facebook.com/zpsiegochaosu"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Facebook size={20} /> facebook/zpsiegochaosu
                            </a>
                        </div>
                        <div className={style.contactType}>
                            <a href="mailto:psichaos@gmail.com">
                                <Mail size={20} /> psichaos@gmail.com
                            </a>
                        </div>
                        </div>
                    </div>
                </div>
                <div className={style.logosContainer}>
                    <div className={style.imageContainer}>
                        <img src="/images/FCI.png" alt="FCI" />
                    </div>
                    <div className={style.imageContainer}>
                        <img src="/images/zkwp.png" alt="ZKWP" />
                    </div>
                </div>
            </div>
        );
    }
}
