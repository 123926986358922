import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import style from "./single-post.module.scss";
import { Circle } from "react-feather";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";

export const SinglePost = ({ postData, dogModal }) => {
    const [index, setIndex] = useState(0);
    return (
        <div
            className={style.singlePostContainer}
            style={dogModal ? { boxShadow: "none" } : { margin: "1rem" }}
        >
            {postData.published_at ? (
                <div className={style.date}>
                    {dayjs(postData.published_at).format("DD/MM/YYYY")}
                </div>
            ) : null}

            <div className={style.title}>
                <h2>{postData.tytul}</h2>
            </div>
            {postData.tresc ? (
                <div className={style.content}>
                    <ReactMarkdown>{postData.tresc}</ReactMarkdown>
                </div>
            ) : null}
            {postData.zdjecia.length ? (
                <div className={style.photosContainer}>
                    <SwipeableViews
                        enableMouseEvents
                        resistance
                        index={index}
                        onChangeIndex={setIndex}
                        containerStyle={{ alignItems: "center" }}
                    >
                        {dogModal
                            ? postData.zdjecia.map((p, i) => {
                                  return (
                                      <div
                                          className={style.imageContainer}
                                          key={i}
                                      >
                                          <img src={p} alt="" />
                                      </div>
                                  );
                              })
                            : postData.zdjecia.map((p, i) => {
                                  return (
                                      <div
                                          className={style.imageContainer}
                                          key={i}
                                      >
                                          <img src={p.url} alt="" />
                                      </div>
                                  );
                              })}
                    </SwipeableViews>
                    <div className={style.pagination}>
                        {postData.zdjecia.map((p, i) => {
                            return (
                                <Circle
                                    color="#ff8e71"
                                    size={18}
                                    key={i}
                                    fill={i === index ? "#ff8e71" : "none"}
                                    onClick={() => setIndex(i)}
                                />
                            );
                        })}
                    </div>
                </div>
            ) : null}
        </div>
    );
};
